<!--  -->
<template>
  <div class="leftTwo">
    <div class="monthly-new-user">
      月新增用户渠道占比
    </div>
    <div id="leftTwoCharts">

    </div>
  </div>
</template>

<script>
export default {
  name: "leftTwo",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myLeftTwoCharts: null
    }
  },
  created() {
    if (!this.myLeftTwoCharts) {
      this.$nextTick(() => {
        var data = [
          {
            value: 1048, name: '新聚浪',
            itemStyle: {
              normal: {//颜色渐变
                color: new this.$echarts.graphic.LinearGradient(
                  1, 1, 1, 0,  //(上-下 渐变)
                  // 1, 1, 0, 0,  //(左上-右下 渐变)
                  // 1, 0, 0, 0,  //(左-右 渐变)
                  // 0, 1, 1, 0,  //(右上-左下 渐变)
                  // 0, 1, 1, 1,  //(左-右 渐变)
                  // 1, 0, 1, 1,  //(上-下 渐变)
                  // 0, 0, 1, 0,  //(左-右 渐变)
                  // 0, 0, 0, 1,  //(上-下 渐变)
                  [
                    { offset: 1, color: 'rgba(0, 120, 255,0)' },
                    { offset: 0.5, color: 'rgba(0, 120, 255, 1)' },
                    { offset: 0, color: 'rgba(0, 204, 255, 1)' },
                  ]
                )
              }
            }
          },
          {
            value: 735, name: '视频号',
            itemStyle: {
              normal: {//颜色渐变
                color: new this.$echarts.graphic.LinearGradient(
                  1, 1, 1, 0,  //(上-下 渐变)
                  // 1, 1, 0, 0,  //(左上-右下 渐变)
                  // 1, 0, 0, 0,  //(左-右 渐变)
                  // 0, 1, 1, 0,  //(右上-左下 渐变)
                  // 0, 1, 1, 1,  //(左-右 渐变)
                  // 1, 0, 1, 1,  //(上-下 渐变)
                  // 0, 0, 1, 0,  //(左-右 渐变)
                  // 0, 0, 0, 1,  //(上-下 渐变)
                  [
                    { offset: 0, color: 'rgba(133, 255, 219, 1)' },
                    { offset: 0.5, color: 'rgba(0, 255, 198, 1)' },
                    { offset: 1, color: 'rgba(133, 255, 219, 0)' },
                  ]
                )
              }
            }
          },
          {
            value: 580, name: '抖音',
            itemStyle: {
              normal: {//颜色渐变
                color: new this.$echarts.graphic.LinearGradient(
                  1, 1, 1, 0,  //(上-下 渐变)
                  // 1, 1, 0, 0,  //(左上-右下 渐变)
                  // 1, 0, 0, 0,  //(左-右 渐变)
                  // 0, 1, 1, 0,  //(右上-左下 渐变)
                  // 0, 1, 1, 1,  //(左-右 渐变)
                  // 1, 0, 1, 1,  //(上-下 渐变)
                  // 0, 0, 1, 0,  //(左-右 渐变)
                  // 0, 0, 0, 1,  //(上-下 渐变)
                  [
                    { offset: 0, color: 'rgba(255, 138, 0, 1)' },
                    { offset: 0.5, color: 'rgba(255, 96, 0, 1)' },
                    { offset: 1, color: 'rgba(255, 96, 0, 0)' },
                  ]
                )
              }
            }
          },
          {
            value: 484, name: '其他',
            itemStyle: {
              normal: {//颜色渐变
                color: new this.$echarts.graphic.LinearGradient(
                  1, 1, 1, 0,  //(上-下 渐变)
                  // 1, 1, 0, 0,  //(左上-右下 渐变)
                  // 1, 0, 0, 0,  //(左-右 渐变)
                  // 0, 1, 1, 0,  //(右上-左下 渐变)
                  // 0, 1, 1, 1,  //(左-右 渐变)
                  // 1, 0, 1, 1,  //(上-下 渐变)
                  // 0, 0, 1, 0,  //(左-右 渐变)
                  // 0, 0, 0, 1,  //(上-下 渐变)
                  [
                    { offset: 0, color: 'rgba(180, 255, 73, 1)' },
                    { offset: 0.5, color: 'rgba(84, 255, 0, 1)' },
                    { offset: 1, color: 'rgba(84, 255, 0, 0)' },
                  ]
                )
              }
            }
          },
        ]
        var dataTotal = 0
        data.forEach(item => {
          dataTotal += +item.value
        })
        console.log(dataTotal)
        this.option = {
          title: {
            top: 'center',
            left: 70,
            text: [
              '{a|月新增总人数}',
              `{b|${dataTotal}人}`
            ].join('\n'),
            textStyle: {
              rich: {
                a: {
                  fontSize: 14,
                  color: '#fff',
                  fontWeight: 'bold',
                  lineHeight: 35,
                  align: 'center',
                },
                b: {
                  fontSize: 14,
                  color: '#fff',
                  fontWeight: 'normal',
                  align: 'center',
                }
              }
            }
          },
          tooltip: {
            trigger: 'item'
          },
          legend: [
            // top: '5%',
            // left: 'right',
            {
              right: 25,
              orient: 'vertical',  //垂直显示
              y: '12.5%',    //延Y轴居中
              // x: 'right', //居右显示
              icon: 'circle',
              data: ['新聚浪'],
              borderWidth: 1,
              borderColor: 'rgba(79, 157, 245, 0.35)',
              borderRadius: 5,
              textStyle: { color: '#fff' },
              formatter: function (name) {
                const obj = data.find(item => item.name === name);
                return `${obj.name.padStart(4, ' ')}     ${(obj.value * 100 / dataTotal).toFixed(1)}%`
              }
            },
            {
              right: 25,
              orient: 'vertical',  //垂直显示
              y: '37.5%',    //延Y轴居中
              // x: 'right', //居右显示
              icon: 'circle',
              data: ['视频号'],
              borderWidth: 1,
              borderColor: 'rgba(79, 157, 245, 0.35)',
              borderRadius: 5,
              textStyle: { color: '#fff' },
              formatter: function (name) {
                const obj = data.find(item => item.name === name);
                return `${obj.name.padStart(4, ' ')}     ${(obj.value * 100 / dataTotal).toFixed(1)}%`
              }
            },
            {
              right: 25,
              orient: 'vertical',  //垂直显示
              y: '62.5%',    //延Y轴居中
              // x: 'right', //居右显示
              icon: 'circle',
              data: ['抖音'],
              borderWidth: 1,
              borderColor: 'rgba(79, 157, 245, 0.35)',
              borderRadius: 5,
              textStyle: { color: '#fff' },
              formatter: function (name) {
                const obj = data.find(item => item.name === name);
                return `${obj.name.padStart(4, ' ')}     ${(obj.value * 100 / dataTotal).toFixed(1)}%`
              }
            },
            {
              right: 25,
              orient: 'vertical',  //垂直显示
              y: '87.5%',    //延Y轴居中
              // x: 'right', //居右显示
              icon: 'circle',
              data: ['其他'],
              borderWidth: 1,
              borderColor: 'rgba(79, 157, 245, 0.35)',
              borderRadius: 5,
              textStyle: { color: '#fff' },
              formatter: function (name) {
                const obj = data.find(item => item.name === name);
                return `${obj.name.padStart(4, '   ')}     ${(obj.value * 100 / dataTotal).toFixed(1)}%`
              }
            }
          ],
          series: [
            {
              name: '月新增用户数量',
              type: 'pie',
              radius: ['45%', '65%'],
              avoidLabelOverlap: true,
              left: -150,
              label: {
                color: '#fff'
              },
              labelLine: {
                show: true,
                length: 3,
                length2: 8
              },
              data: data
            }
          ]
        }
        this.myLeftTwoCharts = this.$echarts.init(document.querySelector('#leftTwoCharts'))
        this.myLeftTwoCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.myLeftTwoCharts.resize()
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.leftTwo {
  display: flex;
  flex-direction: column;
}

#leftTwoCharts {
  width: 385px;
  height: 225px;
  background: url("../../assets/img/charts_bg.png");
  background-size: cover;
  padding-bottom: 4%;
}

.monthly-new-user {
  font-size: 20px;
  font-family: FZZ;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  font-weight: 700;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}
</style>