<!--  -->
<template>
  <div class="rightOne">
    <div class="user-age">
      用户年龄分析
    </div>
    <div id="rightOneCharts">
      <img src="../../assets/img/ta.png" style="width: 166px;height: 178px;margin-left: 20px;">
      <div class="right-body">
        <div class="right-body-item">
          <div class="item-line1">

          </div>
          <div class="item-text" style="left: 15px;">
            <div class="right-body-item-title">
              40岁及以上用户
            </div>
            <div class="right-body-item-subTitle">
              10%
            </div>
          </div>
        </div>
        <div class="right-body-item">
          <div class="item-line2">

          </div>
          <div class="item-text" style="left: 37px;">
            <div class="right-body-item-title">
              30-40岁用户
            </div>
            <div class="right-body-item-subTitle">
              15%
            </div>
          </div>
        </div>
        <div class="right-body-item">
          <div class="item-line3">

          </div>
          <div class="item-text" style="left: 65px;">
            <div class="right-body-item-title">
              20-30岁用户
            </div>
            <div class="right-body-item-subTitle">
              25%
            </div>
          </div>
        </div>
        <div class="right-body-item">
          <div class="item-line4">

          </div>
          <div class="item-text" style="left: 90px;">
            <div class="right-body-item-title">
              18-20岁下用户
            </div>
            <div class="right-body-item-subTitle">
              50%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rightOne",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.rightOne {
  display: flex;
  flex-direction: column;
}

#rightOneCharts {
  width: 385px;
  height: 250px;
  display: flex;
  align-items: center;
  background: url("../../assets/img/charts_bg.png");
  background-size: cover;
  padding-bottom: 4%;
}

.right-body {
  height: 190px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .right-body-item {
    height: 44px;
    position: relative;
    display: flex;
    // align-items: center;

    .item-line1 {
      width: 80px;
      height: 4px;
      background: #73E9DA;
      margin-top: 9px;
      position: absolute;
      left: -65px;
      top: 0;
    }

    .item-line1::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      left: -5px;
      background: #73E9DA;
      border-radius: 50%;
    }

    .item-line1::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      right: -5px;
      background: #73E9DA;
      border-radius: 50%;
    }

    .item-line2 {
      width: 80px;
      height: 4px;
      background: #7FD3FF;
      margin-top: 9px;
      position: absolute;
      left: -43px;
      top: 0;
    }

    .item-line2::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      left: -5px;
      background: #7FD3FF;
      border-radius: 50%;
    }

    .item-line2::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      right: -5px;
      background: #7FD3FF;
      border-radius: 50%;
    }


    .item-line3 {
      width: 80px;
      height: 4px;
      background: #7FD3FF;
      margin-top: 9px;
      position: absolute;
      left: -15px;
      top: 0;
    }

    .item-line3::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      left: -5px;
      background: #7FD3FF;
      border-radius: 50%;
    }

    .item-line3::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      right: -5px;
      background: #7FD3FF;
      border-radius: 50%;
    }


    .item-line4 {
      width: 80px;
      height: 4px;
      background: #7FD3FF;
      margin-top: 9px;
      position: absolute;
      left: 10px;
      top: 0;
    }

    .item-line4::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      left: -5px;
      background: #7FD3FF;
      border-radius: 50%;
    }

    .item-line4::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      right: -5px;
      background: #7FD3FF;
      border-radius: 50%;
    }

    .item-text {
      position: absolute;
      top: 0;
      padding-left: 10px;

      .right-body-item-title {
        font-size: 12px;
        color: #FFFFFF;
      }

      .right-body-item-subTitle {
        font-size: 13px;
        font-weight: 700;

        color: #E3EAF2;
      }
    }

  }
}

.user-age {
  font-size: 20px;
  font-family: FZZ;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  font-weight: 700;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}
</style>