<!--  -->
<template>
  <div class="rightThree">
    <div class="monthly-ascend">
      本月同比与环比增长率
    </div>
    <div id="rightThreeCharts">

    </div>
  </div>
</template>

<script>
export default {
  name: "rightThree",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myRightThreeCharts: null
    }
  },
  created() {
    if (!this.myRightThreeCharts) {
      this.$nextTick(async () => {
        // 生成一个0-200的随机数 升序
        let data = [];
        for (let i = 0; i < 12; i++) {
          data.push(Math.round(Math.random() * 200))
        }
        let data2 = [];
        for (let i = 0; i < 12; i++) {
          data2.push(Math.round(Math.random() * 200))
        }

        data = data.sort((a, b) => a - b)
        data2 = data2.sort((a, b) => a - b)

        this.option = {
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            left: 15,
            right: 15,
            bottom: 10,
            top: 50,
            containLabel: true
          },
          xAxis: {
            show: false,
            type: 'category',
            boundaryGap: false,
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            label: {
              show: false
            }
          },
          yAxis: {
            // type: 'value',
            axisLabel: {
              formatter: (params) => {
                return params + '%'
              },
              color: '#fff'
            },
            max: 200,
            min: 0,
            splitNumber: 10,
            // maxInterval: 20,
            splitLine: {
              show: false
            }
          },
          series: [
            {
              name: '同比',
              type: 'line',
              // stack: 'Total',
              data: data
            },
            {
              name: '环比',
              type: 'line',
              // stack: 'Total',
              data: data2
            },
          ]
        };
        this.myRightThreeCharts = this.$echarts.init(document.querySelector('#rightThreeCharts'))
        this.myRightThreeCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.myRightThreeCharts.resize()
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.rightThree {
  display: flex;
  flex-direction: column;
}

#rightThreeCharts {
  width: 385px;
  height: 255px;
  // background-color: rgba(0, 0, 0, 0.03);
  background: url("../../assets/img/charts_bg.png");
  background-size: cover;
}

.monthly-ascend {
  font-size: 20px;
  font-family: FZZ;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  font-weight: 700;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}
</style>