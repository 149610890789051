<!--  -->
<template>
  <div class="leftOne">
    <div class="monthly-user">
      数据访问量(pv uv)
    </div>
    <div id="leftOneCharts">

    </div>
  </div>
</template>

<script>
export default {
  name: "leftOne",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myLeftOneCharts: null
    }
  },
  created() {
    if (!this.myLeftOneCharts) {
      this.$nextTick(async () => {
        var xDate = ['1-17', '1-18', '1-19', '1-20', '1-21', '1-22', '1-23']

        var data1 = [1985, 815, 735, 570, 527, 522, 4842];
        var data2 = [109.305, 109.335, 109.365, 109.365, 109.41, 109.425, 109.71];
        // 统计百分比
        // var data1 = [14, 15, 65, 24, 75, 23, 24, 43, 27, 72, 12, 53];
        // var data2 = [45, 65, 24, 74, 75, 23, 42, 54, 13, 32, 52, 13];
        var json = {
          chart0: {
            xcategory: xDate,
            low: data1,
            lowLine: [],
          }
        };
        var json2 = {
          chart0: {
            xcategory: xDate,
            low: data2,
            lowLine: [],
          }
        };
        var zrUtil = this.$echarts.util;
        zrUtil.each(json.chart0.xcategory, function (item, index) {
          json.chart0.lowLine.push([{
            coord: [index, json.chart0.low[index]]
          }, {
            coord: [index + 1, json.chart0.low[index + 1]]
          }]);
        });
        zrUtil.each(json.chart0.xcategory, function (item, index) {
          json2.chart0.lowLine.push([{
            coord: [index, json2.chart0.low[index]]
          }, {
            coord: [index + 1, json2.chart0.low[index + 1]]
          }]);
        });

        this.option = {
          // backgroundColor: 'rgba(0,0,0,0)',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                color: '#15ecf4'
              },
            },
            // backgroundColor: 'rgba(0,0,0,0)',
            extraCssText: 'box-shadow: 4px 4px 10px rgba(21, 250, 255,.6);',
          },
          legend: {
            textStyle: {
              fontSize: 12,
              color: '#fff'
            },
            top: '5%',
            right: '5%'
          },
          grid: {
            bottom: 25,
            left: 50,
            right: 50,
          },
          xAxis: {
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff',
              },
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: true
            },
            data: xDate,
          },
          yAxis: [
            {
              name: '人',
              nameTextStyle: {
                padding: [30, 0, 0, -30],
              },
              max: 6000,
              min: 0,
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                  color: '#4b4d64'
                }
              },
              axisLabel: {
                textStyle: { //改变刻度字体样式
                  color: '#ffffff'
                }
              },
            },
            {
              name: '万人',
              nameTextStyle: {
                padding: [-30, 0, 0, 30],
              },
              position: 'right',
              max: 109.7,
              min: 109.2,

              interval: 0.1,
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                  color: '#4b4d64'
                }
              },
              axisLabel: {
                textStyle: { //改变刻度字体样式
                  color: '#ffffff'
                }
              },
            },
          ],
          series: [{
            name: '用户访问量(人)',
            type: 'line',
            // smooth: true,
            symbol: 'circle',
            symbolSize: 3,
            itemStyle: {
              normal: {
                color: '#ffcb00'
              }
            },
            data: data1
          },
          {
            name: '用户访问量(人)',
            type: 'lines',
            coordinateSystem: 'cartesian2d',
            zlevel: 1,
            smooth: true,
            symbol: 'circle',
            effect: {
              show: true,
              smooth: true,
              period: 2,
              symbolSize: 5
            },
            lineStyle: {
              normal: {
                color: '#ffcb00',
                width: 0,
                opacity: 0,
                curveness: 0,
              }
            },
            data: json.chart0.lowLine
          },
          {
            yAxisIndex: 1,
            name: '页面访问量(万人)',
            type: 'line',
            // smooth: true,
            symbol: 'circle',
            symbolSize: 3,
            itemStyle: {
              normal: {
                color: '#15faff'
              }
            },
            data: data2
          },
          {
            yAxisIndex: 1,

            name: '页面访问量(万人)',
            type: 'lines',
            coordinateSystem: 'cartesian2d',
            zlevel: 1,
            smooth: true,
            symbol: 'circle',
            effect: {
              show: true,
              smooth: true,
              period: 2,
              symbolSize: 5
            },
            lineStyle: {
              normal: {
                color: '#15faff',
                width: 0,
                opacity: 0,
                curveness: 0,
              }
            },
            data: json2.chart0.lowLine
          },
          ]
        };
        this.myLeftOneCharts = this.$echarts.init(document.querySelector('#leftOneCharts'))
        this.myLeftOneCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.myLeftOneCharts.resize()
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.leftOne {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.1%;
}

#leftOneCharts {
  width: 385px;
  height: 270px;
  background: url("../../assets/img/charts_bg.png");
  background-size: cover;
  padding-bottom: 4%;
}

.monthly-user {
  font-size: 20px;
  font-family: FZZ;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  font-weight: 700;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}
</style>