<!--  -->
<template>
  <div class="middleMapBottom">
    <div class="middleMapBottom-uv">
      <div class="middleMapBottom-uv-title">
        <img src="../../assets/img/shaokao.png" style="width: 19px;height: 27px;">
        <div style="marginLeft: 15px;">
          烧烤
        </div>
      </div>
      <div class="middleMapBottom-uv-num">
        <div class="middleMapBottom-uv-num-item" v-for="item in shaokaoNum">
          {{ item }}
        </div>
        <div>
          店铺
        </div>
      </div>
    </div>
    <div class="middleMapBottom-pv">
      <div class="middleMapBottom-pv-title">
        <img src="../../assets/img/yadi.png" style="width: 19px;height: 27px;">
        <div style="marginLeft: 15px;">
          雅迪电动车
        </div>
      </div>
      <div class="middleMapBottom-pv-num">
        <div class="middleMapBottom-pv-num-item" v-for="item in yadiNum">
          {{ item }}
        </div>
        <div>
          店铺
        </div>
      </div>
    </div>
    <div id="middleMapBottom">

    </div>
  </div>
</template>

<script>
import xuzhouArea from '../../assets/geo/xuzhou/xuzhouArea.json'
import dateList from './date.json'
export default {
  name: "middleMapBottom",
  components: {

  },
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myMiddleMapCharts: null,
      shaokaoNum: '',
      yadiNum: '',
      dateList,
      timer: null
    }
  },
  created() {


    let res1 = 0
    let res2 = 0

    var markPointData = []
    this.dateList.forEach(item => {
      let obj = {
        name: item.name,
        address: item.address,
        symbolSize: [19, 27],
        coord: [item.lon, item.lat],
        value: [item.lon, item.lat],
        label: {
          show: false
        },
        ...item
      }
      if (item.type == 'shaokao') {
        res1++
        obj.symbol = 'image://https://xjl.static.nicewifi.cn/bigScreen/shaokao.png'
      } else {
        res2++
        obj.symbol = 'image://https://xjl.static.nicewifi.cn/bigScreen/yadi.png'
      }
      markPointData.push(obj)
    })

    this.shaokaoNum = res1 + ''
    this.shaokaoNum = this.shaokaoNum.padStart(5, '0')
    this.yadiNum = res2 + ''
    this.yadiNum = this.yadiNum.padStart(5, '0')

    if (!this.myMiddleMapCharts) {
      this.$nextTick(async () => {

        this.myMiddleMapCharts = this.$echarts.init(document.querySelector('#middleMapBottom'))
        this.$echarts.registerMap('xuzhouAreaMap', xuzhouArea);
        this.option = {
          tooltip: {
            trigger: 'item',
            show: true,
            enterable: true,
            textStyle: {
              fontSize: 20,
              color: '#fff'
            },
            padding: 0,
            position: 'right',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            borderWidth: 0,
            formatter: function (params) {
              if (params.seriesType == "map") return
              if (params.data.type == 'yadi') {
                // 说明这是一个雅迪店铺
                return `
                <div style='width:200px;border-radius:9px;overflow:hidden;transform: scale(1);'>
                    <div  style='width:200px;height:30px;background: rgba(1, 214, 240, 0.8);font-size:12px;font-weight:700;padding:0 6px;
                    overflow:hidden;border: 0;'>
                      ${params.data.name}
                    </div>
                    <div style='width:200px;background: rgba(1, 214, 240, 0.56);font-size:12px;padding:6px 6px;
                    white-space: wrap;line-height:1.5em;'>
                      ${params.data.address}
                    </div>
                </div>
                `
              } else {
                return `
                <div style='width:200px;border-radius:9px;overflow:hidden;transform: scale(1);'>
                    <div  style='width:200px;height:30px;background: #FFBE18;font-size:12px;font-weight:700;padding:0 6px;
                    overflow:hidden;color:#65511F;border: 0;'>
                      ${params.data.name}
                    </div>
                    <div style='width:200px;background: rgba(227, 169, 21, 0.56);font-size:12px;padding:6px 6px;
                    white-space: wrap;line-height:1.5em;'>
                      ${params.data.address}
                    </div>
                </div>
                `
              }
            }
          },
          dataZoom: {
            type: 'inside'
          },
          grid: {
            left: '5%',
            right: '5%',
            bottom: '3%',
            top: '25%',
          },
          geo: [
            {
              map: 'xuzhouAreaMap',
              aspectScale: 0.9,
              roam: false, // 是否允许缩放
              zoom: 1.1, // 默认显示级别
              layoutSize: '95%',
              layoutCenter: ['70%', '50%'],
              itemStyle: {
                normal: {
                  areaColor: {
                    type: 'linear-gradient',
                    x: 0,
                    y: 400,
                    x2: 0,
                    y2: 0,
                    colorStops: [{
                      offset: 0,
                      color: 'rgba(37,108,190,0.3)' // 0% 处的颜色
                    }, {
                      offset: 1,
                      color: 'rgba(15,169,195,0.3)' // 50% 处的颜色
                    }],
                    global: true // 缺省为 false
                  },
                  borderColor: '#4ecee6',
                  borderWidth: 1
                },
                emphasis: {
                  areaColor: {
                    type: 'linear-gradient',
                    x: 0,
                    y: 300,
                    x2: 0,
                    y2: 0,
                    colorStops: [{
                      offset: 0,
                      color: 'rgba(37,108,190,1)' // 0% 处的颜色
                    }, {
                      offset: 1,
                      color: 'rgba(15,169,195,1)' // 50% 处的颜色
                    }],
                    global: true // 缺省为 false
                  }
                }
              },
              label: {
                show: false
              },
              emphasis: {
                show: false,
                itemStyle: {
                  show: false,
                  areaColor: '#0160AD'
                },
                label: {
                  show: false,
                  color: 'rgba(255,255,255,0)'
                }
              },
              zlevel: 3,
              silent: true
            },
            {
              tooltip: {
                show: false
              },
              map: 'xuzhouAreaMap',
              aspectScale: 0.9,
              roam: false, // 是否允许缩放
              zoom: 1.1, // 默认显示级别
              layoutSize: '95%',
              layoutCenter: ['70%', '50%'],
              itemStyle: {
                normal: {
                  borderColor: 'rgba(192,245,249,.6)',
                  borderWidth: 2,
                  shadowColor: '#2C99F6',
                  shadowOffsetY: 0,
                  shadowBlur: 120,
                  areaColor: 'rgba(29,85,139,.2)'
                }
              },
              emphasis: {
                show: false,
                itemStyle: {
                  areaColor: '#0160AD'
                },
                label: {
                  show: 0,
                  color: '#fff'
                }
              },
              zlevel: 2,
              silent: true
            },
            {
              tooltip: {
                show: false
              },
              map: 'xuzhouAreaMap',
              aspectScale: 0.9,
              roam: false, // 是否允许缩放
              zoom: 1.1, // 默认显示级别
              layoutSize: '95%',
              layoutCenter: ['70%', '52.5%'],
              itemStyle: {
                // areaColor: '#005DDC',
                areaColor: 'rgba(0,27,95,0.4)',
                borderColor: '#004db5',
                borderWidth: 1
              },
              zlevel: 1,
              silent: true
            },

          ],
          series: [
            // map
            {
              "name": "shop",
              "type": "scatter",
              "coordinateSystem": "geo",
              label: {
                show: false,
              },
              geoIndex: 0,

              z: 999,
              zlevel: 999,
              data: markPointData
            }
          ]
        }
        this.myMiddleMapCharts.setOption(this.option)
        this.startShow()
      })
    }
  },
  mounted() {

  },
  methods: {
    startShow() {
      let that = this
      var index = 0; //播放所在下标
      this.timer = setInterval(function () {
        that.myMiddleMapCharts.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: index
        });
        index++;
        if (index >= that.dateList.length) {
          index = 0;
        }
      }, 1300)
      this.myMiddleMapCharts.on('mouseover', function (params) {
        // console.log(params)
        clearInterval(that.timer);
        that.myMiddleMapCharts.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: params.dataIndex,
        });
      });
      this.myMiddleMapCharts.on('mouseout', function (params) {
        that.timer && clearInterval(that.timer);
        that.timer = setInterval(function () {
          that.myMiddleMapCharts.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: index
          });
          index++;
          if (index >= that.dateList.length) {
            index = 0;
          }
        }, 1300);
      });
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.middleMapBottom {
  width: 100%;
  height: 100%;
  position: relative;

  .middleMapBottom-uv {
    position: absolute;
    width: 350px;
    height: 50%;
    top: 0;
    left: 0;
    // background-color: red;
    padding-left: 50px;
    font-size: 15px;
    font-weight: 700;

    .middleMapBottom-uv-title {
      margin: 30px 0 17px 0;
      display: flex;
      align-items: center;
    }

    .middleMapBottom-uv-num {
      display: flex;
      align-items: center;

      .middleMapBottom-uv-num-item {
        width: 32px;
        height: 68px;
        border-radius: 5px;
        background: #173B75;
        box-sizing: border-box;
        border: 1px solid #11A7FC;
        font-size: 20px;
        color: #FFCC15;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
      }
    }
  }

  .middleMapBottom-pv {
    position: absolute;
    width: 350px;
    height: 50%;
    top: 50%;
    left: 0;
    // background-color: red;
    padding-left: 50px;
    font-size: 15px;
    font-weight: 700;

    .middleMapBottom-pv-title {
      margin: 30px 0 17px 0;
      display: flex;
      align-items: center;

    }

    .middleMapBottom-pv-num {
      display: flex;
      align-items: center;

      .middleMapBottom-pv-num-item {
        width: 32px;
        height: 68px;
        border-radius: 5px;
        background: #173B75;
        box-sizing: border-box;
        border: 1px solid #11A7FC;
        font-size: 20px;
        color: #FFCC15;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
      }
    }
  }
}

#middleMapBottom {
  width: 100%;
  height: 100%;
}
</style>