<!--  -->
<template>
  <div class="rightTwo">
    <div class="hot-topics">
      今日热门板块分析
    </div>
    <div id="rightTwoCharts">

    </div>
  </div>
</template>

<script>
export var data = [
  { value: 1140, name: '行风热线' },
  { value: 388, name: '张慧帮你问' },
  { value: 326, name: '12345' },
  { value: 300, name: '推广活动' }
]
export default {
  name: "rightTwo",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myRightTherrCharts: null
    }
  },
  created() {
    if (!this.myRightTherrCharts) {
      this.$nextTick(async () => {
        var data = [
          {
            value: 40, name: '行风热线',
            borderColor: 'rgba(0, 153, 255, 1)',
            borderWidth: 2,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                1, 1, 0, 0,  //(左上-右下 渐变)
                [
                  { offset: 0, color: 'rgba(7, 32, 225, 1)' },
                  { offset: 0.5, color: 'rgba(12, 75, 248, 1)' },
                  { offset: 1, color: 'rgba(15, 140, 253, 1)' }
                ]
              )
            }
          },
          {
            value: 38, name: '张慧帮你问',
            borderColor: 'rgba(179, 4, 0, 1)',
            borderWidth: 2,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                1, 1, 0, 0,  //(左上-右下 渐变)
                [
                  { offset: 0, color: 'rgba(226, 72, 11, 1)' },
                  { offset: 0.5, color: 'rgba(247, 116, 19, 1)' },
                  { offset: 1, color: 'rgba(234, 116, 18, 1)' }
                ]
              )
            }
          },
          {
            value: 32, name: '12345',
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                1, 1, 0, 0,  //(左上-右下 渐变)
                [
                  { offset: 0, color: 'rgba(23, 140, 33, 1)' },
                  { offset: 0.5, color: 'rgba(34, 177, 53, 1)' },
                  { offset: 1, color: 'rgba(51, 228, 85, 1)' }
                ]
              )
            }
          },
          {
            value: 30, name: '推广活动',
            itemStyle: {

              color: new this.$echarts.graphic.LinearGradient(
                1, 1, 0, 0,  //(左上-右下 渐变)
                [
                  { offset: 0, color: 'rgba(118, 0, 242, 1)' },
                  { offset: 0.5, color: 'rgba(165, 0, 248, 1)' },
                  { offset: 1, color: 'rgba(185, 38, 255, 1)' }
                ]
              )
            }
          },
        ]
        var dataTotal = 0
        data.forEach(item => {
          dataTotal += +item.value
        })
        this.option = {
          tooltip: {
            trigger: 'item'
          },
          title: {
            top: 'center',
            left: 113,
            text: [
              "{a|总浏览量}",
              "{b|" + dataTotal + "}",
            ].join("\n"),
            textStyle: {
              width: "40",
              color: "#fff",
              rich: {
                a: {
                  color: "#fff",
                  fontSize: 14,
                  align: 'center',
                  fontWeight: "bold",
                  lineHeight: '30',
                },
                b: {
                  color: "#fff",
                  fontSize: 12,
                  align: 'center',
                },
              },
            },
          },
          legend: {
            right: 0,
            orient: 'vertical',  //垂直显示
            y: 'center',    //延Y轴居中
            itemWidth: 15,
            itemHeight: 15,
            textStyle: {
              color: '#fff',
              rich: {
                name: {
                  color: '#fff',
                  padding: [40, 0, 0, 0]
                },
                num: {
                  fontSize: 12,
                  padding: [10, 10]
                }
              }
            },
            formatter(name) {
              var tarValue;
              for (var i = 0; i < data.length; i++) {
                if (data[i].name == name) {
                  tarValue = data[i].value;
                }
              }
              var v = tarValue;
              return name + '\t\t' + tarValue + '人次'
            },
            itemGap: 20,
          },
          series: [
            {
              name: '热门板块分析',
              type: 'pie',
              radius: [40, 79],
              center: ['36%', '50%'],
              roseType: 'area',
              // left: -165,
              label: {
                position: 'outside',
                formatter: params => {
                  return `${(params.value * 100 / dataTotal).toFixed(1)}%`
                },
                color: '#fff'
              },
              labelLine: {
                show: false,
                length: 3,
                length2: 5
              },
              emphasis: {
                show: true
              },
              data: data
            }
          ]
        };
        this.myRightTherrCharts = this.$echarts.init(document.querySelector('#rightTwoCharts'))
        this.myRightTherrCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.myRightTherrCharts.resize()
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.rightTwo {
  display: flex;
  flex-direction: column;
}

#rightTwoCharts {
  width: 385px;
  height: 237px;
  background: url("../../assets/img/charts_bg.png");
  background-size: cover;
  padding-bottom: 4%;
}

.hot-topics {
  font-size: 20px;
  font-family: FZZ;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  font-weight: 700;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}
</style>