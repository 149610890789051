<!--  -->
<template>
  <div class="leftThree">
    <div class="seven">
      平台交易数据前三名
    </div>
    <div id="leftThreeCharts">

    </div>
  </div>
</template>

<script>
export default {
  name: "leftThree",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myLeftThreeCharts: null
    }
  },
  created() {
    if (!this.myLeftThreeCharts) {

      this.$nextTick(() => {
        var data = [
          {
            value: 455, name: '徐州广电赢未来文化传媒有限公司',
            itemStyle: {
              normal: {//颜色渐变
                color: '#e3832c'
              }
            }
          },
          {
            value: 45, name: '泉山区雅品轩火锅店',
            itemStyle: {
              normal: {//颜色渐变
                color: '#00bd37'
              }
            }
          },
          {
            value: 30, name: '徐州市鼓楼区昌盛德羊羯子饭店',
            itemStyle: {
              normal: {//颜色渐变
                color: '#00ffd9'
              }
            }
          },
          {
            value: 15, name: '其他',
            itemStyle: {
              normal: {//颜色渐变
                color: '#00abfa'
              }
            }
          },
        ]
        var dataTotal = 0
        data.forEach(item => {
          dataTotal += +item.value
        })
        console.log(dataTotal)
        this.option = {
          title: {
            top: 100,
            left: 86,
            text: [
              "{a|交易总金额}",
              "{b| " + dataTotal + "万}",
            ].join("\n"),
            textStyle: {
              width: "40",
              color: "#fff",
              rich: {
                a: {
                  color: "#fff",
                  fontSize: 12,
                  align: 'center',

                },
                b: {
                  color: "#fff",
                  fontSize: 14,
                  lineHeight: '30',
                  align: 'center',
                  fontWeight: "bold"

                },
              },
            },
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            right: 0,
            top: 8,
            // y: '12.5%',
            icon: 'circle',
            align: 'left',
            orient: 'vertical',  //垂直显示
            textStyle: {
              position: 'center',
              color: '#fff',
              rich: {
                a: {
                  color: "#fff",
                  fontSize: 12,
                  fontWeight: "bold"
                },
                b: {
                  color: "#fff",
                  fontSize: 12,
                  lineHeight: '35',
                  fontWeight: "bold",
                  fontStyle: 'italic'
                },
                c: {
                  color: "#fff",
                  fontSize: 12,
                  fontWeight: "bold",
                  lineHeight: '35',
                  marginLeft: 'auto'
                },
                d: {
                  color: "#C4DFFF",
                  fontSize: 12,
                }
              }
            },
            itemGap: 25,
            formatter: function (name) {
              const obj = data.find(item => item.name === name)
              return [
                `{a|${obj.name}}`,
                `{d|交易总金额}:{b|${obj.value}}万元 占比{c|${(obj.value * 100 / dataTotal).toFixed(1)}%}`
              ].join("\n")



              // `{a|${obj.name}}
              // {b|${obj.value} {c|${(obj.value * 100 / dataTotal).toFixed(1)}}`
            }
          },
          series: [
            {
              name: '销售额（万元）',
              type: 'pie',
              radius: ['34%', '53%'],
              avoidLabelOverlap: true,
              left: -190,
              labelLine: {
                show: true,
                length2: 0
              },
              data: data,
              label: {
                color: '#fff',
                formatter: params => {
                  return `${(params.value * 100 / dataTotal).toFixed(1)}%`
                },
              },
            }
          ]
        }
        this.myLeftTwoCharts = this.$echarts.init(document.querySelector('#leftThreeCharts'))
        this.myLeftTwoCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.myLeftThreeCharts.resize()
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.leftThree {
  display: flex;
  flex-direction: column;
}

#leftThreeCharts {
  width: 412px;
  height: 244px;
  background: url("../../assets/img/charts_bg.png");
  background-size: cover;
}

.seven {
  font-size: 20px;
  font-family: FZZ;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  font-weight: 700;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}
</style>