<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <div class="d-flex jc-center">
            <div class="title">
              <img src="../assets/img/userViews_title.png" class="title-img">
            </div>
          </div>
        </div>
        <div class="content-body">
          <div class="content-left">
            <div class="weather">
              <img :src="tqImg[tianqi.weather] || tianqi.weatherPic" style="width: 47px;height: 47px;margin-right: 12px;">
              <div style="font-size: 33px;display: flex;align-items: flex-start;">
                <span style="height: 100%;">{{ tianqi.temperature.trim() }} </span><span
                  style="font-size: 22px;vertical-align: text-top;height: 100%;"> ℃</span>
              </div>
            </div>
            <leftOne ref="charts1" class="content-left-item"></leftOne>
            <leftTwo ref="charts2" class="content-left-item"></leftTwo>
            <leftThree ref="charts3" class="content-left-item"></leftThree>
          </div>
          <div class="content-mid">
            <middleMap ref="charts7"></middleMap>
          </div>
          <div class="content-right">
            <div class="content-time">
              <div class="content-time-curtime">
                {{ hours }}{{ minutes }}{{ seconds }}
              </div>
              <div class="content-time-curday">
                <div>
                  {{ intYear }}-{{ intMonth }}-{{ intDay }}
                </div>
                <div>
                  {{ weekday[week] }}
                </div>
              </div>
            </div>
            <rightOne ref="charts4" class="content-right-item"></rightOne>
            <rightTwo ref="charts5" class="content-right-item"></rightTwo>
            <rightThree ref="charts6" class="content-right-item"></rightThree>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'

import leftOne from '../components/userViews/leftOne.vue';
import leftTwo from '../components/userViews/leftTwo.vue';
import leftThree from '../components/userViews/leftThree.vue';
import middleMap from '../components/userViews/middleMap.vue';
import rightOne from '../components/userViews/rightOne.vue';
import rightTwo from '../components/userViews/rightTwo.vue';
import rightThree from '../components/userViews/rightThree.vue';

//weather-icon
import qingTian from '../assets/icon/tqing.png'
import duoYun from '../assets/icon/tduoyun.png'
import yinTian from '../assets/icon/tyewanyintian.png'
import xiaoYu from '../assets/icon/txiaoyu.png'
import zhongYu from '../assets/icon/tzhongyu.png'
import daYu from '../assets/icon/tdayu.png'
import leiYu from '../assets/icon/tleiyu.png'
import baoYu from '../assets/icon/tdayu.png'
import xiaoXue from '../assets/icon/txiaoxue.png'
import zhongXue from '../assets/icon/tzhongxue.png'
import daXue from '../assets/icon/tdaxue.png'
import baoXue from '../assets/icon/tdaxue.png'
import yuJiaXue from '../assets/icon/tyujiaxue.png'
import yeWan from '../assets/icon/tyewan.png'

export default {
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],
      tianqi: '',
      tqImg: {
        '晴': qingTian,
        '多云': duoYun,
        '阴': yinTian,
        '雷雨': leiYu,
        '雨夹雪': yuJiaXue,
        '小雨': xiaoYu,
        '中雨': zhongYu,
        '大雨': daYu,
        '暴雪': baoYu,
        '大暴雨': baoYu,
        '小雪': xiaoXue,
        '中雪': zhongXue,
        '大雪': daXue,
        '暴雪': baoXue,
        '雨': xiaoYu,
        '雪': xiaoXue,
        // '阵雨': ,
        // '特大暴雨': ,
        // '阵雪': ,
        // '雷阵雨伴有冰雹': ,
        // '雾': ,
        // '冻雨': ,
        // '沙尘暴': ,
        // '暴雨到大暴雨': ,
        // '小到中雪': ,
        // '中到大雪': ,
        // '大到暴雪': ,
        // '浮尘': ,
        // '扬沙': ,
        // '强沙尘暴': ,
        // '霾': ,
        // '无数据':
      }
    }
  },
  components: {
    leftOne,
    leftTwo,
    leftThree,
    middleMap,
    rightOne,
    rightTwo,
    rightThree
  },
  mounted() {
    this.initWeather()
    this.tick()
    this.timeFn()
    this.cancelLoading()
  },
  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    async initWeather() {
      const res = await this.$http.get('https://xjl.nicewifi.cn/mall/buyer/other/weather/now?adCode=320300')
      if (res.data.code == 0) {
        this.tianqi = res.data.data
      }
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    tick() {
      var today;
      var hours;
      var minutes;
      var seconds;
      today = new Date();
      var intYear = today.getFullYear();
      var intMonth = today.getMonth() + 1;
      var intDay = today.getDate();
      var intHours = today.getHours();
      var intMinutes = today.getMinutes();
      var intSeconds = today.getSeconds();
      var week = today.getDay();
      if (intHours == 0) {
        hours = "00:";
      }
      else if (intHours < 10) {
        hours = "0" + intHours + ":";
      } else {
        hours = intHours + ":";
      }
      if (intMinutes < 10) {
        minutes = "0" + intMinutes + ":";
      } else {
        minutes = intMinutes + ":";
      }
      if (intSeconds < 10) {
        seconds = "0" + intSeconds + " ";
      } else {
        seconds = intSeconds + " ";
      }
      this.intYear = intYear
      this.intMonth = intMonth
      this.intDay = intDay
      this.hours = hours
      this.minutes = minutes
      this.seconds = seconds
      this.week = week
      setTimeout(() => {
        this.tick()
      }, 1000);
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';


.content-body {
  width: 100%;
  // height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0 40px;
  box-sizing: border-box;
}


.content-title {
  width: 100%;
  text-align: center;

  .title-img {
    width: 10.3vw;
    height: 5.8vh;
    object-fit: contain;
  }
}

.content-left {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 4%;
  position: relative;

  .weather {
    width: 260px;
    height: 50px;
    position: absolute;
    left: 0;
    top: -20px;
    // background-color: red;
    display: flex;
    align-items: center;
  }
  .content-left-item {
    flex: 1;
  }
}

.content-mid {
  flex: 2.78;
  height: 100%;
  padding-top: 4%;

}

.content-right {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 4%;
  position: relative;

  .content-right-item {
    flex: 1;
  }

  .content-time {
    width: 260px;
    height: 50px;
    position: absolute;
    right: 0;
    top: -20px;
    display: flex;

    .content-time-curtime {
      height: 50px;
      font-size: 33px;
      color: rgba(255, 255, 255, 1);
      line-height: 50px;
    }

    .content-time-curday {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 17px;
      height: 50px;
      font-size: 13px;
      color: rgba(197, 197, 197, 1);
    }
  }
}
</style>
